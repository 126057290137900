import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import ShipkooEcommerceFulfillmentAdvantage from 'images/blog-images/shipkoo-ecommerce-fulfillment-advantage.png';
import EcommerceFulfillmentAdvantage from 'images/blog-images/ecommerce-fulfillment-advantage.png';
import EcommerceFulfillmentSMEFeatured from 'images/featured-image/ecommerce-fulfillment-sme-featured.png';
import LogisticsDigitalInnovation from 'images/blog-images/logistics-digital-innovation.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query EcommerceFulfillmentSME {
      file(relativePath: { eq: "shipkoo-ecommerce-fulfillment-advantage.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="ecommerce fulfillment how can we help smes"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="eCommerce Fulfillment - How we can help SMEs | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-ecommerce-fulfillment" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "eCommerce Fulfillment - How we can help SMEs",
              content: intl.formatMessage({ id: "blog-post-meta-title-ecommerce-fulfillment" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: EcommerceFulfillmentSMEFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="ecommerce-fulfillment-how-we-can-help-smes"
        title="eCommerce Fulfillment - How we can help SMEs"
        date="2020-09-30"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              We have often heard the term "logistics" in recent years, and you may immediately think of online shopping, express delivery, etc. In fact, logistics is a key concept that is commonly used in large enterprises, small and medium enterprises, and even online shops. It can be said to be one of the prerequisites for business success today. In the following paragraph, we will introduce the basic steps of fulfillment and logistics for everyone, and see how Shipkoo solutions can help SMEs in this regard.
            </p>

            <h5>
              <strong>
                What is eCommerce Fulfillment?
              </strong>
            </h5>

            <p>
              As long as any commercial activity involves physical goods, it is necessary to set up or rent warehouses for long-term or short-term storage of goods. At the same time, the warehouse is also used as a place for operations such as storage, loading and unloading, picking and packing, transportation, and distribution of goods. Therefore, the management of the warehouse (warehouse management/inventory management) is very important. This is what we call <Link to="/services/ecommerce-fulfillment/">eCommerce Fulfillment</Link> in Shipkoo. Once it is not well managed, it is easy to have consequences such as retention, expiration, damage, shortage or surplus of goods, which will cause serious adverse effects on operations.
            </p>

            <p>
              Nowadays, warehouse management is not about hiring a few warehouse clerks to manage goods as primitive as manual records. In Shipkoo, we adopt a set of tailor-made processes, and use advanced technology to control the receipt and delivery of goods, balances, and other operations to fully guarantee the inventory of goods is intact and ensures the normal operation of production and business activities.
            </p>

            <p>
              By using data analytics, our warehouse management will also classify and record the quantity and quality of various goods, and integrate it with the sales system or your eCommerce platform, and interact with other logistics activities. This is the "inventory management system." You could keep track of your activities through our Shipkoo <a href="https://app.shipkoo.com/register" target="_blank" rel="noreferrer">Order Management System</a> (OMS) by creating your own account.
            </p>

            <Image src={LogisticsDigitalInnovation} className="" alt="logistics digital innovation"/>

            <h5>
              <strong>
                Advantages of eCommerce Fulfillment
              </strong>
            </h5>

            <p>
              Constructing a complete and efficient inventory management system is an indispensable and important strategy for modern enterprises. When warehouse management and fulfillment centers have standardized operating procedures, they can not only improve the quality and efficiency of logistics and transportation, but also save manpower and inventory costs, and bring the following advantages to enterprises:
            </p>

            <UnorderedList>
              <li>
                <span className="text-green">Improve the efficiency and flexibility of the delivery of goods</span> <br />
                You can effectively reduce the lead time waiting for the arrival of the goods, or reduce the cycle of receiving the goods. Because the accuracy of sending goods is greatly improved, delays caused by sending wrong goods can be effectively avoided, so that companies can maintain flexibility in the market and respond to market changes more quickly.
              </li>
              <li>
                <span className="text-green">Establish a warehouse database</span><br />
                A large amount of inventory scheduling data is guaranteed. After these data are established as a database in Shipkoo’s OMS, they will help the company improve administrative efficiency. For example, the distribution of powers and responsibilities for cargo management will be clearer, and if something goes wrong, you can know who is responsible only through the database. With the database, the entire transportation process can be monitored by itself, and how to reduce expenditure can be analyzed from the data to further optimize the logistics process.
              </li>
              <li>
                <span className="text-green">Establish a complete fulfillment SOP</span><br />
                The so-called SOP is the "Standard Operation Procedure". When an enterprise has a complete set of warehousing and logistics SOPs, it can be more clear how to optimize logistics manpower and equipment, and it will be easier to achieve the set goals in employee training.
              </li>
            </UnorderedList>

            <Image src={ShipkooEcommerceFulfillmentAdvantage} className="" alt="advantages of ecommerce fulfillment"/>

            <h5>
              <strong>
                How Shipkoo can help SMEs?
              </strong>
            </h5>

            <p>
              A big worry for SMEs in warehousing is the instability of monthly inbound and outbound shipments. If you reluctantly rent a large warehouse, the rental expense is high and the space may not be used up, which is equivalent to a waste. However, if the rented space is too small, your business may not have enough space for stock during peak seasons or promotional days.
            </p>

            <p>
              Shipkoo can provide SMEs with the most appropriate fulfillment and shipping services. Flexible storage prices, only charged according to actual usage, which help you to save a lot of fixed costs. Our cloud inventory management allows you to manage every item in real time. You and your customers can track delivery anytime, anywhere through our website or OMS.
            </p>

            <p>
              Shipkoo’s unique integrated system allows you to directly place orders and pick up the goods. You just need to do a few clicks on our user-friendly OMS, Shipkoo will deliver the goods from our global fulfillment centers to your customers, or from the supplier to the customer, making your logistics become smarter, faster and easier than ever.
            </p>

            <Image src={EcommerceFulfillmentAdvantage} className="" alt="shipkoo ecommerce fulfillment advantage"/>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;